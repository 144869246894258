<template>
  <div class="flex justify-content-between items-center  hover:bg-gray-100 rounded-md ">
    <div class="flex  items-center space-x-4 py-3 my-2 mx-2 px-2">
      <div v-if="warning === true">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"/><path fill="currentColor" d="M15 8h2v11h-2zm1 14a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 22z"/></svg>
      </div>
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <router-link :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.artists && song.artists.data && song.artists.data[0] && song.artists.data[0].avatar ? song.artists.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
        </router-link>
      </div>
      <div class="flex-1">
        <router-link :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          {{ song.title }} <span class="text-gray-500" v-if="song.subtitle">{{ song.subtitle }}</span>
        </router-link>
        <div class="text-sm text-gray-500 mt-0.5" v-if="song.artists && song.artists.data">
          <span class="people-in-song">
            <PeopleOfSong :people="song.artists.data" type="artist"/>
          </span>
          <router-link v-if="song.uploader" :to="{name: 'UserDetail', params: {id: song.uploader.id}}" class="text-dot">
            {{ song.uploader.username }}
          </router-link>
          <span class="text-dot">
            {{ timeago(song.created_at) }}
          </span>
  <!--        <span class="ml-3">-->
  <!--          <i class="far fa-heart"></i>-->
  <!--        </span>-->
        </div>
        <div class="text-sm text-gray-500 mt-0.5" v-if="song.users && song.users.data">
          <span class="people-in-song">
            <users-of-song :people="song.users.data"/>
          </span>
          <router-link v-if="song.uploader" :to="{name: 'UserDetail', params: {id: song.uploader.id}}" class="text-dot">
            {{ song.uploader.username }}
          </router-link>
          <span class="text-dot">
            {{ timeago(song.created_at) }}
          </span>
        </div>
        <div class="text-sm text-gray-500 mt-1" :title="thousandFormat(song.views) + ' lượt nghe'">
          <i class="uil uil-headphones"></i> {{ numberFormat(song.views) }}
        </div>
      </div>
    </div>
    <div v-if="warning === true && isLoggedIn === true && showBtn === true">
      <router-link style="background:#711313" class="text-white mt-3 flex justify-content-center px-3 py-2 rounded-md items-center" :to="{name: 'UploadAdd', params: {prefix: 'thay-the-ban-thu'}, query: {url: 'https://bcdcnt.net/' + prefix + '/' + song.slug + '-' + song.id + '.html'}}">
        Khôi phục file nhạc
      </router-link>
    </div>
  </div>
</template>

<script>
import PeopleOfSong from "./PeopleOfSong";
import {timeago, numberFormat, thousandFormat} from "../../core/services/utils.service";
import UsersOfSong from "./UserOfSong";

export default {
  name: "SongItemUpload",
  props: {
    prefix: String,
    warning: Boolean,
    showBtn: Boolean,
    isLoggedIn: Boolean,
    song: {
      type: Object,
      default() {
        return {
          title: "",
          uploader: null
        };
      }
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  },
  components: {
    UsersOfSong,
    PeopleOfSong
  }
}
</script>
