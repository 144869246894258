<template>
  <div class="">
    <empty-thumbnail-box/>
  </div>
</template>

<script>
import EmptyThumbnailBox from "./EmptyThumbnailBox.vue";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "EmptyThumbnail",
  components: {EmptyThumbnailBox},
  mounted() {
    changePageTitle("Cần bạn trợ giúp: Ảnh minh họa");
  }
}
</script>
