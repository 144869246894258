<template>
  <div class="card p-3">
    <h2 class="text-xl font-semibold">Chưa có ảnh minh họa</h2>
    <div class="flex justify-between flex-col-reverse lg:flex-row">
      <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
        <ul uk-switcher="connect: #emptyThumbnailSongs-tab; animation: uk-animation-fade">
          <li><a href="#">Tân nhạc <span>{{ thousandFormat(emptyThumbnailSongs.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Dân ca <span>{{ thousandFormat(emptyThumbnailFolks.paginatorInfo.total) }}</span></a></li>
          <li><a href="#">Khí nhạc <span>{{ thousandFormat(emptyThumbnailInstrumentals.paginatorInfo.total) }}</span></a>
          </li>
          <li><a href="#">Th.viên hát <span>{{ thousandFormat(emptyThumbnailKaraokes.paginatorInfo.total) }}</span></a>
          </li>
          <li><a href="#">Tiếng thơ <span>{{ thousandFormat(emptyThumbnailPoems.paginatorInfo.total) }}</span></a></li>
        </ul>
      </nav>
    </div>

    <div class="uk-switcher" id="emptyThumbnailSongs-tab">
      <div>
        <div class="mt-4" v-if="emptyThumbnailSongs.data.length">
          <song-item-upload v-for="song in emptyThumbnailSongs.data" :song="song" prefix="bai-hat"
                            :key="'song' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyThumbnailSongs.paginatorInfo.lastPage > 1"
              v-model="emptyThumbnailSongs.paginatorInfo.currentPage"
              :pages="emptyThumbnailSongs.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyThumbnailSongs"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyThumbnailFolks.data.length">
          <song-item-upload v-for="song in emptyThumbnailFolks.data" :song="song" prefix="dan-ca"
                            :key="'folk' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyThumbnailFolks.paginatorInfo.lastPage > 1"
              v-model="emptyThumbnailFolks.paginatorInfo.currentPage"
              :pages="emptyThumbnailFolks.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyThumbnailFolks"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyThumbnailInstrumentals.data.length">
          <song-item-upload v-for="song in emptyThumbnailInstrumentals.data" :song="song" prefix="dan-ca"
                            :key="'instrumental' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyThumbnailInstrumentals.paginatorInfo.lastPage > 1"
              v-model="emptyThumbnailInstrumentals.paginatorInfo.currentPage"
              :pages="emptyThumbnailInstrumentals.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyThumbnailInstrumentals"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyThumbnailKaraokes.data.length">
          <song-item-upload v-for="song in emptyThumbnailKaraokes.data" :song="song" prefix="thanh-vien-hat"
                            :key="'karaoke' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyThumbnailKaraokes.paginatorInfo.lastPage > 1"
              v-model="emptyThumbnailKaraokes.paginatorInfo.currentPage"
              :pages="emptyThumbnailKaraokes.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyThumbnailKaraokes"
          />
        </div>
      </div>
      <div>
        <div class="mt-4" v-if="emptyThumbnailPoems.data.length">
          <song-item-upload v-for="song in emptyThumbnailPoems.data" :song="song" prefix="tieng-tho"
                            :key="'poem' + song.id"/>
        </div>
        <div class="mt-4">
          <v-pagination
              class="justify-content-center"
              v-if="emptyThumbnailPoems.paginatorInfo.lastPage > 1"
              v-model="emptyThumbnailPoems.paginatorInfo.currentPage"
              :pages="emptyThumbnailPoems.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadEmptyThumbnailPoems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SongItemUpload from "../song/SongItemUpload";
import ApiService from "../../core/services/api.service";
import {
  thousandFormat
} from "../../core/services/utils.service";

export default {
  name: "EmptyThumbnailBox",
  components: {SongItemUpload},
  data() {
    return {
      emptyThumbnailSongs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyThumbnailFolks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyThumbnailInstrumentals: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyThumbnailKaraokes: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      emptyThumbnailPoems: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadEmptyThumbnailSongs() {
      let query = `query($page: Int) {
        songs(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "image_id", value: null}, {column: "image_id", value: 0}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyThumbnailSongs.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.songs) {
              this.emptyThumbnailSongs = data.data.songs;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyThumbnailFolks() {
      let query = `query($page: Int) {
        folks(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "image_id", value: null}, {column: "image_id", value: 0}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyThumbnailFolks.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.folks) {
              this.emptyThumbnailFolks = data.data.folks;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyThumbnailInstrumentals() {
      let query = `query($page: Int) {
        instrumentals(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "image_id", value: null}, {column: "image_id", value: 0}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyThumbnailInstrumentals.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.instrumentals) {
              this.emptyThumbnailInstrumentals = data.data.instrumentals;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyThumbnailKaraokes() {
      let query = `query($page: Int) {
        karaokes(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "image_id", value: null}, {column: "image_id", value: 0}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            users(first: 10) {
              data {
                id
                username
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyThumbnailKaraokes.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.karaokes) {
              this.emptyThumbnailKaraokes = data.data.karaokes;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadEmptyThumbnailPoems() {
      let query = `query($page: Int) {
        poems(first: 10, page: $page, orderBy: [{column: "views", order: DESC}], where: {OR: [{column: "image_id", value: null}, {column: "image_id", value: 0}]}) {
          data {
            id
            slug
            title
            views
            created_at
            thumbnail {
              url
            }
            artists(first: 10) {
              data {
                id
                slug
                title
                avatar {
                  url
                }
              }
            }
            uploader {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.emptyThumbnailPoems.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.poems) {
              this.emptyThumbnailPoems = data.data.poems;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
  },
  mounted() {
    this.loadEmptyThumbnailSongs();
    this.loadEmptyThumbnailFolks();
    this.loadEmptyThumbnailInstrumentals();
    this.loadEmptyThumbnailKaraokes();
    this.loadEmptyThumbnailPoems();
  }
}
</script>
